import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  Rating,
} from "@material-tailwind/react";
import axios from "axios";
import { useState, useEffect } from "react";
const TABLE_HEAD = ["Name", "Rating", "Review Title", "Review Description"];

export default function RatingsTable() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    const getPublicReviews = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://us-east-1.aws.data.mongodb-api.com/app/application-0-edbpg/endpoint/getpublicreviews",
        headers: {},
      };
      const result = await axios
        .request(config)
        .then((response) => {
          setReviews(response.data);
        })
        .catch((error) => {
          setReviews([]);
        });
      if (result) {
        console.log(result);
        setReviews(result);
      }
    };
    getPublicReviews();
  }, []);
  return (
    <Card className="h-full max-w-5xl">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              Recent Reviews
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Discover what users are saying with these recent reviews:
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {reviews.map(
              ({ title, description, name, submitted, rating }, index) => {
                const isLast = index === reviews.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={name}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {name}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Rating value={rating} readonly={true} />
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {title}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-nowrap">
                        <label className="text-sm break-words max-w-lg">
                          {description}
                        </label>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}
