import { Card, Rating, Typography } from "@material-tailwind/react";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../assets/logo-mobile.svg";
import { AppProvider, useApp } from "../RealmApp";
import RatingsTable from "./RatingsTable";
import { useState, useEffect } from "react";

export function Feedback() {
  const { currentUser, logOut } = useApp();
  const [userName, setUserName] = useState(
    currentUser ? currentUser.customData.userDisplayName : ""
  );
  const [_id, setId] = useState("");
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [ratingValue, setRatingValue] = useState(0);

  useEffect(() => {
    if (currentUser) {
      async function fetchData() {
        const result = await currentUser.functions.GetUserReview();
        setReviewTitle(result.title);
        setReviewDescription(result.description);
        setRatingValue(result.rating);
        setUserName(
          result.name.trim() === ""
            ? currentUser.customData.userDisplayName
            : result.name
        );
      }
      fetchData();
    }
  }, []);
  return (
    <div className="overflow-hidden  overflow-x-scroll  mx-auto dark:bg-[#2b2c37]">
      <div className=" p-4 fixed left-0 bg-white dark:bg-[#2b2c37] z-50 right-0 ">
        <header className=" flex justify-between dark:text-white items-center  ">
          {/* Left Side  */}
          <div className=" flex items-center space-x-2  md:space-x-4">
            <a href="/">
              {" "}
              <img src={Logo} alt=" Logo " className=" h-6 w-6" />
            </a>

            <h3 className=" md:text-2xl  hidden md:inline-block font-bold  font-sans">
              <a href="/">Boards</a>
              <div className=" md:text-xs   md:inline-block font-extralight  font-sans">
                by Copeo
              </div>
            </h3>
          </div>

          {/* Right Side */}
          {currentUser && (
            <>
              <div className=" flex space-x-4 items-center md:space-x-2 ">
                <a href="/">
                  <button
                    className="button bg-[#635fc7] md:block hover:bg-green-600 text-sm m-1"
                    onClick={logOut}
                  >
                    Logout
                  </button>
                </a>
              </div>
            </>
          )}
        </header>
      </div>
      <div className="w-[md] m-10">
        {currentUser && (
          <>
            <Toaster />
            <br />
            <br />
            <h1 className="text-lg font-bold dark:text-white">Your Review</h1>
            <Card className="p-5 max-w-lg">
              <>
                <form class="w-full">
                  <div className="flex items-center gap-2">
                    <Rating
                      defaultValue={0}
                      value={ratingValue}
                      onChange={(value) => setRatingValue(value)}
                    />
                    <Typography color="blue-gray" className="font-medium">
                      {ratingValue}.0 Rated
                    </Typography>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-3 mt-3">
                    <div class="w-full md:w-1/2 px-3 mb-1 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        Your Name
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        placeholder="Jane"
                      />
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-3">
                    <div class="w-full md:w-1/2 px-3 mb-1 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        Title
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        placeholder="Cool!"
                        value={reviewTitle}
                        onChange={(e) => setReviewTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-3">
                    <div class="w-full px-3">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-description"
                      >
                        Description | Feedback
                      </label>
                      <textarea
                        class="appearance-none block w-full h-24 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-description"
                        type="text"
                        value={reviewDescription}
                        onChange={(e) => {
                          setReviewDescription(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </form>
                <button
                  type="submit"
                  onClick={async () => {
                    if (
                      userName.trim() === "" ||
                      ratingValue === 0 ||
                      reviewTitle.trim() === ""
                    ) {
                      toast(
                        "Please fill Name and Title fields and provide a rating"
                      );
                      return;
                    }
                    var reviewObject = {
                      title: reviewTitle,
                      description: reviewDescription,
                      name: userName,
                      submitted: new Date(),
                      rating: ratingValue,
                    };
                    const result =
                      await currentUser.functions.CreateUpdateUserReview(
                        reviewObject
                      );
                    toast("Review and feedback submitted.");
                  }}
                  className=" bg-[#635fc7] button"
                >
                  Submit Feedback
                </button>
              </>
            </Card>
          </>
        )}
      </div>
      <div className={currentUser ? "m-10" : "m-14"}>
        <RatingsTable />
      </div>
    </div>
  );
}
