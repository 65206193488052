import React, { useEffect, useState } from "react";
import Logo from "../assets/logo-mobile.svg";
import iconDown from "../assets/icon-chevron-down.svg";
import iconUp from "../assets/icon-chevron-up.svg";
import elipsis from "../assets/icon-vertical-ellipsis.svg";
import HeaderDropDown from "./HeaderDropDown";
import ElipsisMenu from "./ElipsisMenu";
import AddEditTaskModal from "../modals/AddEditTaskModal";
import AddGenAITasksModal from "../modals/AddGenAITasksModal";
import AddEditBoardModal from "../modals/AddEditBoardModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../modals/DeleteModal";
import boardsSlice from "../redux/boardsSlice";
import { Snackbar } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import sharedIcon from "../assets/icon-shared.png";
import ownerIcon from "../assets/icon-owner.png";

function Header({ setIsBoardModalOpen, isBoardModalOpen, currentUser }) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("Saved Successfully");
  const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
  const [boardType, setBoardType] = useState("add");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isAITaskModalOpen, setIsAITaskModalOpen] = useState(false);

  const dispatch = useDispatch();

  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive);
  const isCurrentUserBoardOwner =
    currentUser && board ? board.owner_id === currentUser.id : false;

  const onDropdownClick = () => {
    setOpenDropdown((state) => !state);
    setIsElipsisMenuOpen(false);
    setBoardType("add");
  };

  const hasPermissions = () => {
    if (
      board.updateTaskStatusOnlyEmails.includes(
        currentUser.customData.userEmail
      ) ||
      board.readAccessEmails.includes(currentUser.customData.userEmail)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const setOpenEditModal = () => {
    setIsBoardModalOpen(true);
    setIsElipsisMenuOpen(false);
  };
  const setOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsElipsisMenuOpen(false);
  };

  const onDeleteBtnClick = async (e) => {
    if (e.target.textContent === "Delete") {
      const deleteBoardResult = await currentUser.functions.DeleteBoard({
        _id: board._id,
      });
      if (deleteBoardResult) {
        dispatch(boardsSlice.actions.deleteBoard());
        dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));
        setIsDeleteModalOpen(false);
        setSnackBarMessage("Deleted the board.");
        setShowSnackBar(true);
      } else {
        if (board.readAccessEmails.includes(currentUser.customData.userEmail)) {
          toast("You only have read permissions for this board.");
        } else if (
          board.updateTaskStatusOnlyEmails.includes(
            currentUser.customData.userEmail
          ) ||
          board.fullAccessEmails.includes(currentUser.customData.userEmail)
        ) {
          toast(
            "This is a shared board. Only the board owner can delete this board."
          );
        } else {
          toast("Couldn't delete the board. Please try again later");
        }

        setIsDeleteModalOpen(false);
      }
    } else {
      setIsDeleteModalOpen(false);
    }
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackBar(false);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      <Toaster />
      <Snackbar
        open={showSnackBar}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleSnackBarClose}
        message={snackBarMessage}
        // action={action}
      />
      {currentUser && (
        <>
          <div className=" p-4 fixed left-0 bg-white dark:bg-[#2b2c37] z-50 right-0 ">
            <header className=" flex justify-between dark:text-white items-center  ">
              {/* Left Side  */}
              <div className=" flex items-center space-x-2  md:space-x-4">
                <a href="/">
                  {" "}
                  <img src={Logo} alt=" Logo " className=" h-6 w-6" />
                </a>{" "}
                <h3 className=" md:text-2xl  hidden md:inline-block font-bold  font-sans">
                  <a href="/">
                    Boards
                    <div className=" md:text-xs   md:inline-block font-extralight  font-sans">
                      by Copeo
                    </div>
                  </a>
                </h3>
                <div className=" flex items-center ">
                  {board.owner_id === currentUser.id ? (
                    <>
                      <img
                        title="You own this board"
                        src={ownerIcon}
                        className="  filter-white  h-8 md:ml-20 hidden sm:block"
                        alt="Owner"
                        onClick={() => {
                          setSnackBarMessage(
                            "You own this board. Tip: Notice the icon with a key."
                          );
                          setShowSnackBar(true);
                        }}
                      />{" "}
                    </>
                  ) : (
                    <>
                      <img
                        title="You do not own this board"
                        src={sharedIcon}
                        className="  filter-white  h-8 md:ml-20 hidden sm:block"
                        alt="Owner"
                        onClick={() => {
                          setSnackBarMessage(
                            "This is a shared board. Tip: Notice the icon with two people with two dots and arrows representing a shared item."
                          );
                          setShowSnackBar(true);
                        }}
                      />{" "}
                    </>
                  )}
                  {windowSize[0] >= 768 ? (
                    <>
                      <button
                        className=" truncate max-w-[150px] md:text-xl text-xl font-bold ml-2 font-sans "
                        onClick={() => {
                          var boardName = "Board Name:\n" + board.name + "\n\n";
                          var boardDescription = board.description
                            ? "Board Description:\n" + board.description
                            : "";
                          toast(boardName + boardDescription);
                        }}
                      >
                        {board.name}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className=" truncate max-w-[150px] md:text-lg text-lg font-bold ml-2 font-sans "
                        onClick={() => {
                          setOpenDropdown(!openDropdown);
                        }}
                      >
                        {board.name}
                      </button>
                    </>
                  )}

                  <div className=" flex space-x-4 items-center md:space-x-2 ">
                    {/* Show shared label when the board is a shared one & not on mobile*/}
                    {/* {board.owner_id === currentUser.id && (
                  <>
                    <i className="text-xs font-extralight font-sans mb-2 ml-5 hidden md:block">
                      Board Permissions:
                      <br />
                      Owner: All
                    </i>
                  </>
                )} */}

                    {/* Show ReadOnly Board Information & not on mobile*/}
                    {board.readAccessEmails.includes(
                      currentUser.customData.userEmail
                    ) && (
                      <>
                        <i className="text-xs font-light font-sans ml-5 hidden md:block">
                          Board Permissions:
                          <br />
                          Read-Only
                        </i>
                      </>
                    )}

                    {/* Show UpdateOnly Board Information & not on mobile*/}
                    {board.updateTaskStatusOnlyEmails.includes(
                      currentUser.customData.userEmail
                    ) && (
                      <>
                        <i className="text-xs font-light font-sans ml-5 hidden md:block">
                          Board Permissions:
                          <br />
                          Can Update Subtasks
                        </i>
                      </>
                    )}

                    {/* Show Full Access Board Information & not on mobile*/}
                    {board.fullAccessEmails.includes(
                      currentUser.customData.userEmail
                    ) && (
                      <>
                        <i className="text-xs font-light font-sans ml-5 hidden md:block">
                          Board Permissions:
                          <br />
                          Shared: All
                        </i>
                      </>
                    )}

                    {/* Board Dropdown in Mobile Devices */}
                    <img
                      src={openDropdown ? iconUp : iconDown}
                      alt=" dropdown icon"
                      className=" w-3 ml-2 md:hidden"
                      onClick={onDropdownClick}
                    />

                    {/* Save Button */}
                    {board && (
                      <button
                        className="button text-xs bg-[#635fc7] md:block hover:bg-green-600 m-1 transition-all duration-500 hover:scale-125"
                        onClick={async () => {
                          const updateResult =
                            await currentUser.functions.UpdateBoard({
                              ...board,
                              newColumns: board.columns,
                            });
                          if (updateResult) {
                            setSnackBarMessage("Saved successfully");
                            setShowSnackBar(true);
                          } else {
                            setSnackBarMessage(
                              "Couldn't save any changes. You may not have permissions."
                            );
                            setShowSnackBar(true);
                          }
                        }}
                      >
                        {" "}
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* Right Side */}
              <div className=" flex space-x-4 items-center md:space-x-2 ">
                {isCurrentUserBoardOwner && (
                  <>
                    <button
                      className=" button hidden text-xs md:block  bg-[#635fc7] transition-all duration-500 hover:scale-110"
                      onClick={() => {
                        if (!hasPermissions()) {
                          toast(
                            "You do not have permissions to add new tasks."
                          );
                        } else {
                          setIsTaskModalOpen((prevState) => !prevState);
                        }
                      }}
                    >
                      + Add Task
                    </button>

                    <button
                      onClick={() => {
                        setIsTaskModalOpen((prevState) => !prevState);
                      }}
                      className=" button py-1 px-3 md:hidden bg-[#635fc7]"
                    >
                      +
                    </button>

                    <button
                      className=" button hidden text-xs md:block  bg-[#635fc7] transition-all duration-500 hover:scale-110 "
                      onClick={() => {
                        if (!hasPermissions()) {
                          toast(
                            "You do not have permissions to add new tasks."
                          );
                        } else {
                          setIsAITaskModalOpen((prevState) => !prevState);
                        }
                      }}
                    >
                      + AI Tasks
                    </button>
                    <Link to="/reports">
                      <button
                        className=" button hidden text-xs md:block  bg-[#635fc7] transition-all duration-500 hover:scale-110"
                        onClick={() => {}}
                      >
                        Reports
                      </button>
                    </Link>
                  </>
                )}
                <img
                  onClick={() => {
                    setBoardType("edit");
                    setOpenDropdown(false);
                    setIsElipsisMenuOpen((prevState) => !prevState);
                  }}
                  src={elipsis}
                  alt="elipsis"
                  className=" cursor-pointer h-6 hover:scale-125 transition-all duration-500 "
                />

                {isElipsisMenuOpen && (
                  <ElipsisMenu
                    type="Boards"
                    setOpenEditModal={setOpenEditModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                  />
                )}
              </div>

              {openDropdown && (
                <HeaderDropDown
                  setOpenDropdown={setOpenDropdown}
                  setIsBoardModalOpen={setIsBoardModalOpen}
                  currentUser={currentUser}
                />
              )}
            </header>
            {isTaskModalOpen && (
              <AddEditTaskModal
                setIsAddTaskModalOpen={setIsTaskModalOpen}
                type="add"
                device="mobile"
              />
            )}
            {isAITaskModalOpen && (
              <AddGenAITasksModal
                setIsAITaskModalOpen={setIsAITaskModalOpen}
                type="add"
                device="mobile"
              />
            )}

            {isBoardModalOpen && (
              <AddEditBoardModal
                setBoardType={setBoardType}
                type={boardType}
                setIsBoardModalOpen={setIsBoardModalOpen}
              />
            )}
            {isDeleteModalOpen && (
              <DeleteModal
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                type="board"
                title={board.name}
                onDeleteBtnClick={onDeleteBtnClick}
              />
            )}
          </div>
        </>
      )}
      {/* For Public Boards */}
      {!currentUser && (
        <>
          <div className=" p-4 fixed left-0 bg-white dark:bg-[#2b2c37] z-50 right-0 ">
            <header className=" flex justify-between dark:text-white items-center  ">
              {/* Left Side  */}
              <div className=" flex items-center space-x-2  md:space-x-4">
                <img src={Logo} alt=" Logo " className=" h-6 w-6" />
                <h3 className=" md:text-2xl  hidden md:inline-block font-bold  font-sans">
                  Boards
                  <div className=" md:text-xs   md:inline-block font-extralight  font-sans">
                    by Copeo
                  </div>
                </h3>

                <div className=" flex items-center ">
                  <h3 className=" truncate max-w-[200px] md:text-2xl text-xl font-bold md:ml-14 font-sans  ">
                    {board.name}
                  </h3>
                  <div className=" flex space-x-4 items-center md:space-x-2 ">
                    {/* Save Button */}
                    {board && (
                      <Link to="/">
                        <button
                          className="button bg-[#635fc7] md:block hover:bg-green-600 text-sm m-1"
                          onClick={async () => {
                            dispatch(boardsSlice.actions.deleteBoard());
                          }}
                        >
                          {" "}
                          Liked boards? Start here
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </header>
            {isTaskModalOpen && (
              <AddEditTaskModal
                setIsAddTaskModalOpen={setIsTaskModalOpen}
                type="add"
                device="mobile"
              />
            )}

            {isBoardModalOpen && (
              <AddEditBoardModal
                setBoardType={setBoardType}
                type={boardType}
                setIsBoardModalOpen={setIsBoardModalOpen}
              />
            )}
            {isDeleteModalOpen && (
              <DeleteModal
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                type="board"
                title={board.name}
                onDeleteBtnClick={onDeleteBtnClick}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Header;
