import React from "react";
import { useApp } from "../RealmApp";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import boardsSlice from "../redux/boardsSlice";

function ElipsisMenu({ type, setOpenEditModal, setOpenDeleteModal }) {
  const { logOut } = useApp();
  const dispatch = useDispatch();
  return (
    <div
      className={
        type === "Boards"
          ? " absolute  top-16  right-5"
          : " absolute  top-6  right-4"
      }
    >
      <div className=" flex justify-end items-center">
        <div className=" w-40 text-sm z-50 font-medium shadow-md shadow-[#364e7e1a] bg-white dark:bg-[#20212c] space-y-4 py-5 px-4 rounded-lg  h-auto pr-12">
          <p
            onClick={() => {
              setOpenEditModal();
            }}
            className="transition-all duration-500 hover:scale-125 cursor-pointer dark:text-gray-400 text-gray-700"
          >
            Edit {type}
          </p>

          <p
            onClick={() => setOpenDeleteModal()}
            className="transition-all duration-500 hover:scale-125 cursor-pointer text-red-500"
          >
            Delete {type}
          </p>
          {type === "Boards" && (
            <p className="transition-all duration-500 hover:scale-125 cursor-pointer dark:text-green-500 text-green-800">
              <Link to="/reports">Board Reports</Link>
            </p>
          )}
          {type === "Boards" && (
            <p className="transition-all duration-500 hover:scale-125 cursor-pointer dark:text-gray-400 text-gray-700">
              <Link to="/account">Account Settings</Link>
            </p>
          )}
          {type === "Boards" && (
            <p
              onClick={async () => {
                await logOut();
                dispatch(boardsSlice.actions.deleteBoards());
                window.location.reload();
              }}
              className="transition-all duration-500 hover:scale-125 cursor-pointer text-red-500"
            >
              Logout
            </p>
          )}
          <br />
          <br />
          {type === "Boards" && (
            <p className="transition-all duration-500 hover:scale-125 cursor-pointer dark:text-gray-400 text-gray-700">
              <Link to="/feedback">Feedback</Link>
            </p>
          )}
          {type === "Boards" && (
            <p className="transition-all duration-500 hover:scale-125 cursor-pointer dark:text-gray-400 text-gray-700">
              <Link to="/releasenotes">What's New</Link>
            </p>
          )}
          {type === "Boards" && (
            <p className="transition-all duration-500 hover:scale-125 cursor-pointer dark:text-gray-400 text-gray-700">
              <Link to="/about">About Dev</Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ElipsisMenu;
