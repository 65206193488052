import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import boardIcon from "../assets/icon-board.svg";
import useDarkMode from "../hooks/useDarkMode";
import darkIcon from "../assets/icon-dark-theme.svg";
import lightIcon from "../assets/icon-light-theme.svg";
import boardsSlice from "../redux/boardsSlice";
import sharedIcon from "../assets/icon-shared.png";
import ownerIcon from "../assets/icon-owner.png";

function HeaderDropDown({ setOpenDropdown, setIsBoardModalOpen, currentUser }) {
  const dispatch = useDispatch();
  const [colorTheme, setTheme] = useDarkMode();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  const boards = useSelector((state) => state.boards);
  const [searchInput, setSearchInput] = useState("");
  const filteredBoards = boards.filter((board) => {
    return board.name.toLowerCase().includes(searchInput.toLowerCase());
  });

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  return (
    <div
      className=" py-5 px-6 absolute left-0 right-0 bottom-[-100vh]  top-16  dropdown overflow-auto"
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        setOpenDropdown(false);
      }}
    >
      {/* DropDown Modal */}

      <div className=" bg-white dark:bg-[#2b2c37] shadow-md shadow-[#364e7e1a]  w-full  py-4 rounded-xl ">
        <h3 className=" dark:text-gray-300 text-gray-600 font-semibold mx-4 mb-2 ">
          ALL BOARDS ({filteredBoards?.length})
        </h3>
        <input
          type="text"
          className=" bg-transparent  px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-1  ring-0 ml-3 dark:text-white mb-2"
          placeholder="Search boards here"
          onChange={handleChange}
          value={searchInput}
        />
        <div
          onClick={() => {
            setIsBoardModalOpen(true);
            setOpenDropdown(false);
          }}
          className=" flex items-baseline space-x-2  text-[#635fc7] px-5 py-4  "
        >
          <img src={boardIcon} className="   filter-white  h-4 " alt="" />
          <p className=" text-sm font-bold  ">Create New Board </p>
        </div>
        <div className=" dropdown-borad overflow-auto max-h-[450px]">
          {filteredBoards.map((board, index) => (
            <div
              className={`flex items-baseline space-x-2 px-5 py-4  ${
                board.isActive &&
                " bg-[#635fc7] rounded-r-full text-white mr-8 "
              } `}
              key={index}
              onClick={() => {
                // dispatch(boardsSlice.actions.setBoardActive({ index }));
                dispatch(
                  boardsSlice.actions.setBoardActiveByBoardId({ board })
                );
                setSearchInput("");
                setOpenDropdown(false);
              }}
            >
              {board.owner_id === currentUser.id ? (
                <>
                  <img
                    title="You own this board"
                    src={ownerIcon}
                    className="  filter-white  h-4 "
                    alt="Owner"
                  />{" "}
                </>
              ) : (
                <>
                  <img
                    title="You do not own this board"
                    src={sharedIcon}
                    className="  filter-white  h-4"
                    alt="Owner"
                  />{" "}
                </>
              )}
              <p className=" text-sm font-bold  ">{board.name}</p>
            </div>
          ))}

          <div className=" mx-2  p-4  space-x-2 bg-slate-100 dark:bg-[#20212c] flex justify-center items-center rounded-lg">
            <img src={lightIcon} alt="sun indicating light mode" />

            <Switch
              checked={darkSide}
              onChange={toggleDarkMode}
              className={`${
                darkSide ? "bg-[#635fc7]" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  darkSide ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>

            <img src={darkIcon} alt="moon indicating dark mode" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderDropDown;
