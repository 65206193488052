import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import boardsSlice from "../redux/boardsSlice";
import { AppProvider, useApp } from "../RealmApp";

function Subtask({ index, taskIndex, colIndex }) {
  const { currentUser, logOut } = useApp();
  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const col = board.columns.find((col, i) => i === colIndex);
  const task = col.tasks.find((task, i) => i === taskIndex);
  const subtask = task.subtasks.find((subtask, i) => i === index);
  const checked = subtask.isCompleted;
  const [comments, setComments] = useState(subtask.comments);

  const onChange = (e) => {
    dispatch(
      boardsSlice.actions.setSubtaskCompleted({ index, taskIndex, colIndex })
    );
  };

  const onCommentsChange = (e) => {
    setComments(e.target.value);
    dispatch(
      boardsSlice.actions.setSubtaskComments({
        index,
        taskIndex,
        colIndex,
        comments,
      })
    );
  };

  return (
    <div className=" w-full flex hover:bg-[#635fc740] dark:hover:bg-[#635fc740] rounded-md relative items-center justify-start dark:bg-[#20212c]  p-3 gap-4  bg-[#f4f7fd]">
      <input
        className=" w-4 h-4  accent-[#635fc7] cursor-pointer "
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <ul>
        <p className={checked && " line-through opacity-30 text-sm"}>
          {subtask.title}
        </p>
        {!checked ? (
          <textarea
            disabled={checked}
            value={comments}
            className=" bg-transparent font-normal  px-4 py-2 rounded-md text-xs w-full border-[0.5px] border-gray-400 focus:outline-[#635fc7] outline-1  ring-0  "
            // onChange={(e) => setComments(e.target.value)}
            onChange={onCommentsChange}
            placeholder="comments if any"
          />
        ) : (
          <i
            className={checked && " line-through opacity-30 font-light text-xs"}
          >
            {comments}
          </i>
        )}
      </ul>
    </div>
  );
}

export default Subtask;
