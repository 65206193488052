import React, { useState } from "react";
import crossIcon from "../assets/icon-cross.svg";
import boardsSlice from "../redux/boardsSlice";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useApp } from "../RealmApp";
import {
  BoardNameTitle,
  AddNewBoardTitle,
  BoardColumnsTitle,
  UpdateSubTaskStatusUserEmailsTitle,
  AddSubTaskStatusUserEmailTitle,
  BoardPublicTitle,
} from "../titles";
import toast, { Toaster } from "react-hot-toast";

function AddEditBoardModal({ setIsBoardModalOpen, type }) {
  const { currentUser } = useApp();
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [_id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isPublic, setIsBoardPublic] = useState(false);
  const [isArchived, setIsBoardArchived] = useState(false);
  const isPremium = currentUser.customData.userPlan === "premium";

  const [newColumns, setNewColumns] = useState([
    { name: "Todo", tasks: [], id: uuidv4() },
    { name: "Doing", tasks: [], id: uuidv4() },
    { name: "Done", tasks: [], id: uuidv4() },
  ]);

  const [readAccessEmails, setReadAccessEmails] = useState([]);
  const [fullAccessEmails, setFullAccessEmails] = useState([]);
  const [updateTaskStatusOnlyEmails, setUpdateTaskStatusOnlyEmails] = useState([
    // { email: "", id: uuidv4() },
  ]);
  const [isValid, setIsValid] = useState(true);
  const board = useSelector((state) => state.boards).find(
    (board) => board.isActive
  );
  const isCurrentUserBoardOwner =
    currentUser && board ? board.owner_id === currentUser.id : false;
  const hasPermissions = () => {
    if (type === "edit") {
      if (
        board.updateTaskStatusOnlyEmails.includes(
          currentUser.customData.userEmail
        ) ||
        board.readAccessEmails.includes(currentUser.customData.userEmail)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  if (type === "edit" && isFirstLoad) {
    setNewColumns(
      board.columns.map((col) => {
        return { ...col, id: uuidv4() };
      })
    );
    setId(board._id);
    setName(board.name);
    setDescription(board.description);
    setIsFirstLoad(false);
    setFullAccessEmails(board.fullAccessEmails);
    setReadAccessEmails(board.readAccessEmails);
    setIsBoardPublic(board.isPublic);
    setIsBoardArchived(board.isArchived);
    setUpdateTaskStatusOnlyEmails(
      board.updateTaskStatusOnlyEmails.map((email) => {
        return { email: email, id: uuidv4() };
      })
    );
  }

  const validate = () => {
    setIsValid(false);
    if (!name.trim()) {
      toast("Board name cannot be empty.");
      return false;
    }
    for (let i = 0; i < newColumns.length; i++) {
      if (!newColumns[i].name.trim()) {
        toast("Board column name cannot be empty.");
        return false;
      }
    }

    for (let i = 0; i < updateTaskStatusOnlyEmails.length; i++) {
      if (!updateTaskStatusOnlyEmails[i].email.trim()) {
        toast("Please fill all the emails or remove the entries.");
        return false;
      }
    }

    for (let i = 0; i < updateTaskStatusOnlyEmails.length; i++) {
      if (
        updateTaskStatusOnlyEmails[i].email.trim().toLowerCase() ===
        currentUser.customData.userEmail.toLowerCase()
      ) {
        toast("Cannot have your email.");
        return false;
      }
    }
    setIsValid(true);
    return true;
  };

  const onChange = (id, newValue) => {
    setNewColumns((prevState) => {
      const newState = [...prevState];
      const column = newState.find((col) => col.id === id);
      column.name = newValue;
      return newState;
    });
  };

  const onUpdateUserEmailChange = (id, newValue) => {
    setUpdateTaskStatusOnlyEmails((prevState) => {
      const newState = [...prevState];
      const column = newState.find((col) => col.id === id);
      column.email = newValue;
      return newState;
    });
  };

  const onDelete = (id) => {
    setNewColumns((prevState) => prevState.filter((el) => el.id !== id));
  };

  const onUpdateUserEmailDelete = (id) => {
    setUpdateTaskStatusOnlyEmails((prevState) =>
      prevState.filter((el) => el.id !== id)
    );
  };

  const onSubmit = async (type) => {
    setIsBoardModalOpen(false);
    if (type === "add") {
      const insertResult = await currentUser.functions.CreateBoard({
        name,
        newColumns,
        readAccessEmails,
        fullAccessEmails,
        updateTaskStatusOnlyEmails,
      });
      dispatch(
        boardsSlice.actions.addBoard({ ...insertResult, isActive: true })
      );
    } else {
      const updateResult = await currentUser.functions.UpdateBoard({
        _id,
        name,
        description,
        newColumns,
        readAccessEmails,
        fullAccessEmails,
        updateTaskStatusOnlyEmails,
        isPublic,
        isArchived,
      });
      if (updateResult) {
        dispatch(
          boardsSlice.actions.editBoard({ ...updateResult, isActive: true })
        );
      } else {
        toast(
          "Couldn't update the board. This happens if you do not have enough permissions. Please try again later."
        );
      }
    }
  };

  return (
    <div
      className="  fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown "
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        // setIsBoardModalOpen(false);
      }}
    >
      <Toaster />

      <div
        className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold
       shadow-md shadow-[#364e7e1a] max-w-md mx-auto my-auto w-full px-8  py-8 rounded-xl"
      >
        <div class="relative  ...">
          <div class="absolute top-0 right-0 ">
            <img
              src={crossIcon}
              alt=" close "
              className=" cursor-pointer "
              onClick={() => {
                setIsBoardModalOpen(false);
              }}
            />
          </div>
        </div>

        <h3 className=" text-lg ">
          {type === "edit" ? "Edit" : "Add New"} {AddNewBoardTitle}
        </h3>

        {/* Board Name */}

        <div className="mt-8 flex flex-col space-y-1">
          <label className="  text-sm dark:text-white text-gray-500">
            {BoardNameTitle}
          </label>
          <input
            className=" bg-transparent  px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-1  ring-0  "
            placeholder=" "
            value={name}
            disabled={!hasPermissions()}
            onChange={(e) => setName(e.target.value)}
            id="board-name-input"
          />
        </div>
        {/* Description */}
        <div className="mt-2 flex flex-col space-y-1">
          <label className="  text-sm dark:text-white text-gray-500">
            Description
          </label>
          <textarea
            value={description}
            disabled={!hasPermissions()}
            onChange={(e) => setDescription(e.target.value)}
            id="board-description-input"
            className=" bg-transparent outline-none min-h-[100px] font-normal focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-[1px] "
            placeholder="Ex: Board Description."
          />
        </div>

        {isCurrentUserBoardOwner && (
          <>
            {/* Making a board public */}
            <div className="mt-4 flex flex-col space-y-1">
              <label className="  text-sm dark:text-white text-gray-500">
                {BoardPublicTitle}
              </label>
              {!isPremium && (
                <label className="  text-xs dark:text-white text-gray-500">
                  (Disabled - Premium Feature)
                </label>
              )}

              <label class="relative inline-flex items-center cursor-pointer ">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  checked={isPublic}
                  onChange={(e) => {
                    setIsBoardPublic(!isPublic);
                  }}
                  disabled={!(isPremium && isCurrentUserBoardOwner)}
                />
                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#635fc7]"></div>
                <span class="ml-3 text-sm font-medium text-gray-400 dark:text-gray-500">
                  {isPublic ? "Public" : "Private"}
                </span>
              </label>
              {isPublic && (
                <>
                  <label className="  text-sm dark:text-white text-gray-500">
                    Public Board Url :
                  </label>
                  <a
                    className="  text-sm dark:text-blue-500 text-[#635fc7]"
                    target="_blank"
                    href={`https://boards.copeo.app/#/board/${board.boardId}`}
                  >{`https://boards.copeo.app/#/board/${board.boardId}`}</a>
                  <label className="  text-xs font-light dark:text-white text-gray-500">
                    Note: For now, you cannot access a public board when you're
                    logged in. To test: copy the url, save the board and paste
                    it in an incognito window.
                  </label>
                </>
              )}
            </div>

            {/* Archive a board */}
            <div className="mt-4 flex flex-col space-y-1">
              <label className="  text-sm dark:text-white text-gray-500">
                Archive this board ?
              </label>
              <label className="  text-xs dark:text-white text-gray-500">
                When enabled, the board will not show up in the boards list
                [Coming Soon]
              </label>
              <label class="relative inline-flex items-center cursor-pointer ">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  checked={isArchived}
                  onChange={(e) => {
                    setIsBoardArchived(!isArchived);
                  }}
                  disabled={!isCurrentUserBoardOwner}
                />
                <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#635fc7]"></div>
                <span class="ml-3 text-sm font-medium text-gray-400 dark:text-gray-500">
                  {isArchived ? "Archived" : "Visible"}
                </span>
              </label>
            </div>
          </>
        )}

        {/* Board Columns */}
        <div className="mt-8 flex flex-col space-y-3">
          <label className=" text-sm dark:text-white text-gray-500">
            {BoardColumnsTitle}
          </label>

          {newColumns.map((column, index) => (
            <div key={index} className=" flex items-center w-full ">
              <input
                className=" bg-transparent flex-grow px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-[1px]  "
                onChange={(e) => {
                  onChange(column.id, e.target.value);
                }}
                type="text"
                disabled={!hasPermissions()}
                value={column.name}
              />
              <img
                src={crossIcon}
                alt=" delete "
                onClick={() => {
                  if (!hasPermissions()) {
                    toast("You do not have permissions to delete columns");
                  } else {
                    onDelete(column.id);
                  }
                }}
                className=" m-4 cursor-pointer "
              />
            </div>
          ))}

          <div>
            <button
              className=" w-full items-center hover:opacity-70 dark:text-[#635fc7] dark:bg-white  text-white bg-[#635fc7] py-2 rounded-full "
              onClick={() => {
                if (!hasPermissions()) {
                  toast("You do not have permissions to add new tasks.");
                } else {
                  setNewColumns((state) => [
                    ...state,
                    { name: "", tasks: [], id: uuidv4() },
                  ]);
                }
              }}
            >
              + Add New Column
            </button>
          </div>
        </div>

        {/* User Access Permission for Update Tasks */}

        <div className="mt-8 flex flex-col space-y-3">
          <label className=" text-sm dark:text-white text-gray-500">
            {UpdateSubTaskStatusUserEmailsTitle}
          </label>

          {updateTaskStatusOnlyEmails.map((column, index) => (
            <div key={index} className=" flex items-center w-full ">
              <input
                className=" bg-transparent flex-grow px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-[1px]  "
                onChange={(e) => {
                  onUpdateUserEmailChange(column.id, e.target.value);
                }}
                disabled={!hasPermissions()}
                type="text"
                placeholder="teammate@email.com"
                value={column.email}
              />
              <img
                src={crossIcon}
                onClick={() => {
                  if (!hasPermissions()) {
                    toast(
                      "You do not have permissions to update user access permissions"
                    );
                  } else {
                    onUpdateUserEmailDelete(column.id);
                  }
                }}
                className=" m-4 cursor-pointer "
                alt=" delete "
              />
            </div>
          ))}
          <div>
            <button
              className=" w-full items-center hover:opacity-70 dark:text-[#635fc7] dark:bg-white  text-white bg-[#635fc7] py-2 rounded-full "
              onClick={() => {
                if (!hasPermissions()) {
                  toast(
                    "You do not have permissions to add new emails or update access permissions."
                  );
                } else {
                  setUpdateTaskStatusOnlyEmails((state) => [
                    ...state,
                    { email: "", id: uuidv4() },
                  ]);
                }
              }}
            >
              {AddSubTaskStatusUserEmailTitle}
            </button>
            <button
              onClick={() => {
                const isValid = validate();
                if (isValid === true) onSubmit(type);
              }}
              className=" w-full items-center hover:opacity-70 dark:text-white dark:bg-green-600 mt-8 relative  text-white bg-green-600 py-2 rounded-full"
            >
              {type === "add" ? "Create New Board" : "Save Changes"}
            </button>
            <button
              onClick={() => {
                setIsBoardModalOpen(false);
              }}
              className=" w-full items-center hover:opacity-70 dark:text-white dark:bg-red-500 mt-1 relative  text-white bg-red-500 py-2 rounded-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEditBoardModal;
