import React from "react";
import { useApp } from "../RealmApp";
import { Container } from "@mui/material";
import settingslogo from "../assets/settings2.png";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { Button, Input } from "@material-tailwind/react";
import atlasConfig from "../atlasConfig.json";
import { Link } from "react-router-dom";
import * as Realm from "realm-web";
import toast, { Toaster } from "react-hot-toast";
import { App } from "realm-web";
const { baseUrl, appId } = atlasConfig;
// {
//   owner_id: user.id,
// userDisplayName: "",
// userPhone:"",
// userBusinessName : "",
// userBusinessAddress: "",
// userTimeZone:"",
// userThemePreference:"light",
// userOrganizationId: generateOrganizationId(),
// userLayoutPreference: "createFixedLayout",
// userNavBarPreferenece: true,
// userProfileImage: ""
// }
export function AccountSettingsPage() {
  const { currentUser, logOut } = useApp();
  const app = new App({ id: appId });

  const customData = currentUser.customData;
  const [userCustomData, setUserCustomData] = useState(
    customData
      ? customData
      : {
          userDisplayName: "",
          userPhone: "",
          userBusinessName: "",
          userBusinessAddress: "",
          userBusinessLogoUrl: "",
          userTimeZone: "",
          userThemePreference: "light",
          userLayoutPreference: "createFixedLayout",
          userNavBarPreferenece: true,
          userProfileImage: "",
        }
  );
  const [displayName, setDisplayName] = useState(
    userCustomData.userDisplayName ? userCustomData.userDisplayName : ""
  );
  const [businessName, setBusinessName] = useState(
    userCustomData.userBusinessName ? userCustomData.userBusinessName : ""
  );
  const [businessLogo, setBusinessLogo] = useState(
    userCustomData.userBusinessLogoUrl ? userCustomData.userBusinessLogoUrl : ""
  );
  const [businessAddress, setBusinessAddress] = useState(
    userCustomData.userBusinessAddress ? userCustomData.userBusinessAddress : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    userCustomData.userPhone ? userCustomData.userPhone : ""
  );
  const [defaultTheme, setDefaultTheme] = useState(
    userCustomData.userThemePreference
      ? userCustomData.userThemePreference
      : "light"
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    currentUser.customData.userTimeZone
      ? currentUser.customData.userTimeZone
      : ""
  );

  return (
    <div className="overflow-hidden  overflow-x-scroll dark:bg-[#2b2c37]">
      <Toaster />
      <center>
        <img src={settingslogo} />
        <h1 className="dark:text-white">Account Settings</h1>
        <br />
        <div className="flex flex-col w-72 items-start gap-2 left-0 bg-white dark:bg-[#2b2c37]">
          <b className="dark:text-white">Display Name:</b>
          <Input
            size="lg"
            value={displayName}
            className="dark:text-white"
            onChange={(event) => {
              const { name, value } = event.target;
              setDisplayName(value);
            }}
          />
          <b className="dark:text-white">Business Name:</b>
          <Input
            size="lg"
            className="dark:text-white"
            value={businessName}
            onChange={(event) => {
              const { name, value } = event.target;
              setBusinessName(value);
            }}
          />
          <b className="dark:text-white">Business Address:</b>
          <Input
            size="lg"
            className="dark:text-white"
            value={businessAddress}
            onChange={(event) => {
              const { name, value } = event.target;
              setBusinessAddress(value);
            }}
          />
          <b className="dark:text-white">Phone Number:</b>
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          <b className="dark:text-white">Your TimeZone (Business/Personal):</b>
          <TimezoneSelect
            className="dark:text-white"
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            timezones={{
              ...allTimezones,
              "America/Lima": "Pittsburgh",
              "Europe/Berlin": "Frankfurt",
            }}
          />
          <b className="dark:text-white">Business Logo:</b>
          <Input
            size="lg"
            className="dark:text-white"
            value={businessLogo}
            type={"url"}
            onChange={(event) => {
              const { name, value } = event.target;
              setBusinessLogo(value);
            }}
          />
          <i className="dark:text-white">(Must be a public URL)</i>
        </div>
        <br />
        <Button
          size="md"
          className="w-40 h-10  bg-green-500"
          ripple={true}
          onClick={async () => {
            const updatedData = {
              userBusinessName: businessName,
              userDisplayName: displayName,
              userPhone: phoneNumber,
              userTimeZone: selectedTimezone.value,
              userBusinessAddress: businessAddress,
              userBusinessLogoUrl: businessLogo,
            };

            const result = await currentUser.functions.UpdateUserCustomData(
              updatedData
            );
            if (result) {
              toast("Saved");
            }
            await currentUser.refreshCustomData();
            //console.log(result);
          }}
        >
          Save{" "}
        </Button>
        <Link to="/">
          <Button
            size="md"
            className="w-40 h-10 ml-2 bg-[#635fc7] "
            ripple={true}
          >
            Back to Home{" "}
          </Button>
        </Link>
        <br />
        <br />
        <Link to="/forgotpassword">
          <Button size="md" className="w-80 h-10  bg-green-700" ripple={true}>
            Looking to Change Password ?{" "}
          </Button>
        </Link>
        <br /> <br />
        <br /> <br />
        <br /> <br />
        <p className="dark:text-white text-gray-500 mt-2">{" Copeo Boards"}</p>
      </center>
    </div>
  );
}
