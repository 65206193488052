import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import crossIcon from "../assets/icon-cross.svg";
import aianimation from "../assets/aianimation.gif";
import boardsSlice from "../redux/boardsSlice";
import { AppProvider, useApp } from "../RealmApp";
import toast, { Toaster } from "react-hot-toast";

function AddGenAITasksModal({
  type,
  device,
  setIsTaskModalOpen,
  setIsAITaskModalOpen,
  taskIndex,
  prevColIndex = 0,
}) {
  const { currentUser, logOut } = useApp();
  const dispatch = useDispatch();
  const [board, setBoard] = useState(
    useSelector((state) => state.boards).find((board) => board.isActive)
  );
  const columns = board.columns;
  const col = columns.find((col, index) => index === prevColIndex);
  const task = col ? col.tasks.find((task, index) => index === taskIndex) : [];
  const [status, setStatus] = useState(columns[prevColIndex].name);
  const [newColIndex, setNewColIndex] = useState(prevColIndex);
  const [isLoading, setIsLoading] = useState(false);
  const [addAsSubTasks, setAddAsSubTasks] = useState(true);
  const [tasktitle, setTaskTitle] = useState("");
  const [searchterms, setSearchTerms] = useState("");
  const [maxItems, setMaxItems] = useState(5);

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
    setNewColIndex(e.target.selectedIndex);
  };

  const onSubmit = async (type) => {
    if (addAsSubTasks) {
      if (tasktitle.trim() === "") {
        toast("Please provide a task title.");
        return;
      }
    }

    if (searchterms.trim() === "") {
      toast(
        "Please provide a high level description of your task to generate the list."
      );
      return;
    }
    try {
      setIsLoading(true);
      const result = await currentUser.functions.GenerateAITasks(
        searchterms,
        maxItems
      );
      var parsedResult = JSON.parse(result.toString());
      if (addAsSubTasks) {
        var subtasks = [];

        for (var v = 0; v < parsedResult.Result.length; v++) {
          subtasks.push({
            title: parsedResult.Result[v],
            isCompleted: false,
            id: uuidv4(),
            comments: "",
          });
        }
        var title = tasktitle;
        var description = searchterms;
        var releasedate = "";
        //dispatch the new task
        dispatch(
          boardsSlice.actions.addTask({
            title,
            description,
            releasedate,
            subtasks,
            status,
            newColIndex,
          })
        );
      } else {
        var subtasks = [
          {
            title: "SubTask",
            isCompleted: false,
            id: uuidv4(),
            comments: "",
          },
        ];

        for (var v = 0; v < parsedResult.Result.length; v++) {
          var title = parsedResult.Result[v];
          var description = "";
          var releasedate = "";

          dispatch(
            boardsSlice.actions.addTask({
              title,
              description,
              releasedate,
              subtasks,
              status,
              newColIndex,
            })
          );
        }
      }
      setIsAITaskModalOpen(false);
      toast("Make sure you save your changes using the save button.");
      setIsAITaskModalOpen(false);
    } catch (error) {
      toast("Couldn't process this request. I'll work on this. ");
      setIsLoading(true);
      setIsAITaskModalOpen(false);
    }
  };

  return (
    <div
      className={
        device === "mobile"
          ? "  py-6 px-6 pb-40  absolute overflow-y-scroll  left-0 flex  right-0 bottom-[-100vh] top-0 dropdown "
          : "  py-6 px-6 pb-40  absolute overflow-y-scroll  left-0 flex  right-0 bottom-0 top-0 dropdown "
      }
      onClick={(e) => {
        //disabled the close when clicked outside the modal
        // if (e.target !== e.currentTarget) {
        //   return;
        // }
        // setIsAITaskModalOpen(false);
      }}
    >
      <Toaster />

      {/* Modal Section */}
      <div
        className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  my-auto  bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold
       shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl"
      >
        <div class="relative  ...">
          <div class="absolute top-0 right-0 ">
            <img
              src={crossIcon}
              alt=" close "
              className=" cursor-pointer "
              onClick={() => {
                setIsAITaskModalOpen(false);
              }}
            />
          </div>
        </div>
        <h3 className=" text-lg ">Add Tasks Using AI</h3>
        {/* User Input */}
        <div className="mt-2 flex flex-col space-y-2">
          {addAsSubTasks && (
            <>
              <label className="  text-sm dark:text-white text-gray-500">
                Task name
              </label>
              <input
                disabled={isLoading}
                value={tasktitle}
                onChange={(e) => setTaskTitle(e.target.value)}
                id="task-description-input"
                className=" bg-transparent outline-none min-h-[10px] focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-[1px] "
                placeholder="Task Name"
              />
            </>
          )}
          <label className="  text-sm dark:text-white text-gray-500">
            Give a high level description of your task list
          </label>

          <textarea
            disabled={isLoading}
            value={searchterms}
            onChange={(e) => setSearchTerms(e.target.value)}
            id="task-description-input"
            className=" bg-transparent outline-none min-h-[100px] focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-[1px] "
            placeholder="Try to be as specific as you can. For example: To-do items for a fencing project"
          />
          {/* Add as subtasks */}
          <div className="flex flex-col space-y-1">
            <label className="  text-sm dark:text-white text-gray-500">
              Add the result list as subtasks{" "}
            </label>
            <label class="relative inline-flex items-center cursor-pointer ">
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                checked={addAsSubTasks}
                onChange={(e) => {
                  setAddAsSubTasks((prevState) => !prevState);
                }}
                disabled={isLoading}
              />
              <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#635fc7]"></div>
              <span class="ml-3 text-sm font-medium text-gray-400 dark:text-gray-500">
                {addAsSubTasks
                  ? "Will be added as subtasks"
                  : "Will be added as tasks"}
              </span>
            </label>
          </div>
          {/* current Status  */}
          <div className="mt-8 flex flex-col space-y-3">
            <label className="  text-sm dark:text-white text-gray-500">
              Add To
            </label>
            <select
              disabled={isLoading}
              value={status}
              onChange={onChangeStatus}
              className=" select-status flex-grow px-4 py-2 rounded-md text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#635fc7] outline-none"
            >
              {columns.map((column, index) => (
                <option key={index}>{column.name}</option>
              ))}
            </select>
          </div>

          <label className=" mt-8 text-sm dark:text-[#635fc7] text-[#635fc7]">
            Optional Settings:
          </label>
          <label className="  text-xs dark:text-white text-gray-500">
            Maximum list items to be generated (Granularity of the tasks)
          </label>

          <select
            disabled={isLoading}
            onChange={(e) => {
              setMaxItems(e.target.value);
            }}
            value={maxItems}
            className="select dark:text-white text-gray-700 bg-white dark:bg-[#2b2c37]"
          >
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>50</option>
            <option>100</option>
          </select>
          <button
            disabled={isLoading}
            className="text-xs mt-4 items-center disabled:bg-gray-400 dark:text-[#635fc7] dark:bg-white  text-white bg-[#635fc7] py-2 rounded-full "
            onClick={onSubmit}
          >
            Proceed
          </button>
          <h6 className=" text-xs text-gray-400 mt-2">
            Note: This is an experimental feature. I am working on this feature
            to improve it further. If this is helping you, please consider
            supporting this project by{" "}
            <a
              href="https://www.paypal.com/paypalme/medhakarri"
              className="text-blue-500"
            >
              donating here
            </a>{" "}
            to accelerate further development.
          </h6>
        </div>
        {isLoading ? (
          <>
            <center className="mt-1">
              Processing your request...
              <img src={aianimation} className=" rounded " />
            </center>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default AddGenAITasksModal;
