import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
  Card,
  List,
  ListItem,
} from "@material-tailwind/react";
import {
  HomeIcon,
  BellIcon,
  WrenchScrewdriverIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid";
import { Button, Input } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import developer from "../assets/devprofilepic.jpeg";

export function ReleaseNotes() {
  return (
    <div className="overflow-hidden  overflow-x-scroll  mx-auto dark:bg-[#2b2c37]">
      <center>
        <Link to="/" className="">
          <Button
            size="md"
            className="w-40 h-10 mt-10  bg-[#635fc7] "
            ripple={true}
          >
            Go Back{" "}
          </Button>
        </Link>
        <div className="w-[32rem]">
          <br />
          <Timeline className="mt-5 ml-3">
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <HomeIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 26, 2023 - Official First Release{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <a
                      href="https://www.youtube.com/watch?v=y8FaniGV558"
                      target="_blank"
                    >
                      <ListItem>
                        <begin>Official Product Launch - Copeo Boards</begin>
                      </ListItem>
                      <ListItem>
                        <begin>Released YouTube Video - Click to open</begin>
                      </ListItem>
                    </a>
                    <ListItem>
                      <begin>Introduced help content</begin>
                    </ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <HomeIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 22-23, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>Improved AI results</begin>
                    </ListItem>
                    <ListItem>
                      <begin>Bug Fixes</begin>
                    </ListItem>
                    <ListItem>
                      <begin>UX Enhancements - Mobile, Desktop</begin>
                    </ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 19-20, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>
                        Artificial Intelligence based tasks / subtasks creation
                      </begin>
                    </ListItem>
                    <ListItem>
                      <begin>UX Enhancements</begin>
                    </ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 15, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>Introduced Board Reports & Charts</begin>
                    </ListItem>
                    <ListItem>
                      <begin>
                        Completed subtasks are displayed at the bottom
                      </begin>
                    </ListItem>
                    <ListItem>
                      <begin>UX Enhancements - Mobile, Desktop</begin>
                    </ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 13, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>Introduced "Public Boards" feature</begin>
                    </ListItem>
                    <ListItem>Introduced Archived Boards</ListItem>
                    <ListItem>Added Board Description</ListItem>
                    <ListItem>Desktop and Mobile UX Enhancements</ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 12, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>Faster Application Load time</begin>
                    </ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 11, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>Account activation by email confirmation</begin>
                    </ListItem>
                    <ListItem>Forgot/Reset Password</ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 10, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>Added Release Notes Page</begin>
                    </ListItem>
                    <ListItem>Added Account Settings</ListItem>
                    <ListItem>Handle Invalid URLs</ListItem>
                    <ListItem>Desktop and Mobile UX Enhancements</ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem>
              <TimelineConnector />
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  June 2, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody className="pb-8">
                <Card>
                  <List>
                    <ListItem>
                      <begin>
                        Added board completion percentage for each board.
                      </begin>
                    </ListItem>
                    <ListItem>
                      Added task completion percentage for each task.
                    </ListItem>
                    <ListItem>
                      Save & Cancel buttons in dialogs - User feedback.
                    </ListItem>
                    <ListItem>Share boards via e-mail.</ListItem>
                  </List>
                </Card>
              </TimelineBody>
            </TimelineItem>
            <TimelineItem className="mb-12">
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <WrenchScrewdriverIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  [Internal Beta] First Release - May 28, 2023{" "}
                </Typography>
              </TimelineHeader>
              <TimelineBody>
                <Typography
                  color="gary"
                  className="font-normal text-gray-600 dark:text-gray-400"
                >
                  First Release of the application with basic features
                </Typography>
              </TimelineBody>
            </TimelineItem>
          </Timeline>
        </div>
      </center>
    </div>
  );
}
