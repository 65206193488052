import { Card, Select, Typography, Option } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import boardsSlice from "../redux/boardsSlice";
import React, { useEffect, useState } from "react";
import Logo from "../assets/logo-mobile.svg";
import HelpImage from "../assets/ExploreTableHelp.png";
import NoBoards from "../assets/noboards.gif";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import Plot from "react-plotly.js";
import TableRenderers from "react-pivottable/TableRenderers";
import Utilities, { aggregators } from "react-pivottable/Utilities";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AppProvider, useApp } from "../RealmApp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Help from "@mui/icons-material/HelpOutline";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function BoardsTable() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [table2Settings, setTable2Settings] = useState({});
  const [chartSettings, setChartSettings] = useState({});
  const [boards, setBoards] = useState([]);
  const { currentUser, logOut } = useApp();

  const boardRows = (boards) => {
    var tableItems = [];
    for (var v = 0; v < boards.length; v++) {
      var board = boards[v];
      var boardName = board.name;
      for (var vv = 0; vv < board.columns.length; vv++) {
        var columnName = board.columns[vv].name;
        var tasks = board.columns[vv].tasks;
        for (var vvv = 0; vvv < tasks.length; vvv++) {
          var taskTitle = tasks[vvv].title;
          var taskDescription = tasks[vvv].description;
          var releaseDate = tasks[vvv].releasedate;
          var subtasks = tasks[vvv].subtasks;
          for (var vvvv = 0; vvvv < subtasks.length; vvvv++) {
            var subTaskTitle = subtasks[vvvv].title;
            var subTaskComments = "";
            var subTaskIsCompleted = subtasks[vvvv].isCompleted;
            var subTaskId = subtasks[vvvv].id;
            var item = {
              BoardName: boardName,
              GroupName: columnName,
              Task: taskTitle,
              TaskDescription: taskDescription,
              ReleaseDate: releaseDate,
              SubTask: subTaskTitle,
              Comments: subTaskComments,
              SubTaskId: subTaskId,
              Status: subTaskIsCompleted ? "Completed" : "Not Completed",
            };
            tableItems.push(item);
          }
        }
      }
    }

    return tableItems;
  };
  const data = boardRows(boards);

  const customCSS = `
  .pvtVal .cell .bold .positive {
    background-color: green !important;
  }

  .pvtVal.cell.bold.negative {
    background-color: blue !important;
  }
`;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    const getBoards = async () => {
      try {
        const includeArchived = true;
        const result = await currentUser.functions.GetBoards(includeArchived);
        if (result) {
          //set the boards
          setBoards(result);
          setIsLoading(false);
        } else {
          toast("Couldn't get boards");
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    if (currentUser) {
      getBoards();
    }
  }, []);
  return (
    // className="bg-[#f4f7fd] flex flex-col dark:bg-[#20212c] "
    <div
      className={
        windowSize[0] >= 768
          ? " bg-[#f4f7fd]  flex flex-col scrollbar-hide h-screen dark:bg-[#20212c] "
          : "bg-[#f4f7fd]  scrollbar-hide h-screen flex    dark:bg-[#20212c] overflow-x-scroll gap-6 "
      }
    >
      <Toaster />

      <div className=" p-4 fixed left-0 bg-white dark:bg-[#2b2c37] z-50 right-0 ">
        <header className=" flex justify-between dark:text-white items-center  ">
          {/* Left Side  */}
          <div className=" flex items-center space-x-2  md:space-x-4">
            <a href="/">
              {" "}
              <img src={Logo} alt=" Logo " className=" h-6 w-6" />
            </a>

            <h3 className=" md:text-2xl  hidden md:inline-block font-bold  font-sans">
              <a href="/">Boards</a>
              <div className=" md:text-xs   md:inline-block font-extralight  font-sans">
                by Copeo
              </div>
            </h3>

            <div className=" flex items-center ">
              <button
                className=" truncate  md:text-xl text-xl font-bold ml-2 font-sans  "
                onClick={() => {}}
              >
                Reports & Charts
              </button>
            </div>
          </div>
          {/* Right Side */}
          <div className=" flex space-x-4 items-center md:space-x-2 ">
            <a href="/">
              <button
                className="button bg-[#635fc7] md:block hover:bg-green-600 text-sm m-1"
                onClick={logOut}
              >
                Logout
              </button>
            </a>
          </div>
        </header>
      </div>

      <br />
      <br />
      <br />
      <br />

      {isLoading ? (
        <>
          <center className=" dark:text-white text-black">
            Loading all your boards
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-20 h-20 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </center>
        </>
      ) : (
        <></>
      )}
      {boards.length > 0 ? (
        <>
          <div className="mt-4 ml-4 container flex flex-col items-start">
            <Toaster />

            <Accordion className="ml-4 " defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <>
                  <Help />
                  How to create your own report ?
                </>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <div className=" font-bold text-[#f4f7fd]]">
                    <u>Create your report view:</u>
                  </div>
                  <div className="mt-1 font-light text-[#f4f7fd]]">
                    Simply drag and drop columns and see the magic
                  </div>
                  <img src={HelpImage} className="" />
                </>
              </AccordionDetails>
            </Accordion>
            <Accordion className="mt-4 ml-4" defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="font-bold text-xl hover:text-[#635FC7] transition duration-300 cursor-pointer  text-blue-500 rounded-lg">
                  <>
                    <h3 className=" font-sans text-[#635FC7]">
                      Pivot Table View of all your boards
                    </h3>
                    <div className=" md:text-xs  hidden md:inline-block font-extralight  font-sans">
                      drag and drop the fields to create your own table{" "}
                    </div>
                  </>
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className="ml-2">
                  <style>{customCSS}</style>

                  <PivotTableUI
                    data={data}
                    onChange={(s) => setTable2Settings(s)}
                    cols={["Status"]}
                    rows={["BoardName"]} //"taskDescription"
                    vals={["SubTask"]}
                    aggregators={{
                      Count: aggregators["Count"],
                      Percentage: aggregators["Count as Fraction of Rows"],
                    }}
                    aggregatorName="Count"
                    rendererName="Table"
                    renderers={{
                      Table: TableRenderers.Table,
                      //   HeatMap: TableRenderers["Table Row Heatmap"],
                      //   Chart: PlotlyRenderers["Stacked Bar Chart"],
                    }}
                    // renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                    {...table2Settings}
                    hiddenAttributes={[
                      "taskTitle",
                      "id",
                      "releaseDate",
                      "subTaskId",
                    ]}
                    hiddenFromAggregators={["releaseDate"]}
                    hiddenFromDragDrop={[
                      "GroupName",
                      //   "Task",
                      "TaskDescription",
                      "ReleaseDate",
                      //   "SubTask",
                      //   "Comments",
                      "SubTaskId",
                    ]}
                    stringLocales={"en"}
                    rendererOptions={{
                      table: {
                        rowTotals: true,
                        colTotals: true,
                      },
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="m-4" defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="font-bold text-xl hover:text-[#635FC7] transition duration-300 cursor-pointer  text-[#635FC7] rounded-lg">
                  <>
                    <h3 className=" md:text-2xl  md:inline-block font-bold  font-sans">
                      Chart View
                    </h3>
                    <div className=" md:text-xs  hidden md:inline-block font-extralight  font-sans">
                      explore, visualize, zoom & export report
                    </div>
                  </>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="mt-2">
                  <PivotTableUI
                    data={data}
                    onChange={(s) => setChartSettings(s)}
                    cols={["Status"]}
                    rows={["BoardName"]} //"taskDescription"
                    vals={["SubTask"]}
                    aggregators={{
                      Count: aggregators["Count"],
                      // Percentage: aggregators["Count as Fraction of Rows"],
                    }}
                    aggregatorName="Count"
                    rendererName="Table"
                    renderers={{
                      //   Table: TableRenderers.Table,
                      //   HeatMap: TableRenderers["Table Row Heatmap"],
                      Chart: PlotlyRenderers["Stacked Bar Chart"],
                    }}
                    // renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                    {...chartSettings}
                    hiddenAttributes={[
                      "taskTitle",
                      "id",
                      "releaseDate",
                      "subTaskId",
                    ]}
                    hiddenFromAggregators={["releaseDate"]}
                    hiddenFromDragDrop={[
                      "GroupName",
                      //   "Task",
                      "TaskDescription",
                      "ReleaseDate",
                      "SubTask",
                      "Comments",
                      "SubTaskId",
                    ]}
                    stringLocales={"en"}
                    rendererOptions={{
                      table: {
                        rowTotals: true,
                        colTotals: true,
                      },
                      heatmap: {
                        colorScaleGenerator: function (values) {
                          var min = Plot.d3.min(
                            values.filter(function (d) {
                              return d !== 0;
                            })
                          );
                          var max = Plot.d3.max(
                            values.filter(function (d) {
                              return d !== 0;
                            })
                          );
                          return (
                            Plot.d3.scale
                              .linear()
                              .domain([min, 0, max])
                              // .range(["#77F", "#FFF", "#F77"]);
                              .range(["lavenderblush", "white", "honeydew"])
                          );
                        },
                      },
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      ) : (
        <div className="self-center dark:text-white text-black">
          <center>
            <img src={NoBoards} alt="no boards found" className=" mt-10" />
            No Boards Found
            <br />
            <Link className="text-blue-500 mt-5" to="/">
              Create your first board now
            </Link>
          </center>
        </div>
      )}
    </div>
  );
}
