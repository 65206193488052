import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import Home from "./components/Home";
import EmptyBoard from "./components/EmptyBoard";
import boardsSlice from "./redux/boardsSlice";
import { AppProvider, useApp } from "./RealmApp";
import atlasConfig from "./atlasConfig.json";
import { Routes, Route } from "react-router-dom";
import { WelcomePage } from "./components/WelcomePage";
import { AccountSettingsPage } from "./components/AccountSettingsPage";
import { ReleaseNotes } from "./components/ReleaseNotes";
import { Feedback } from "./components/Feedback";
import { BoardsLayout } from "./components/BoardsLayout";
import { PublicBoardsLayout } from "./components/PublicBoardsLayout";
import NotFound from "./components/NotFound";
import { ConfirmEmail } from "./components/ConfirmEmail";
import { ForgotPassword } from "./components/ForgotPassword";
import { ResetPassword } from "./components/ResetPassword";
import BoardsTable from "./components/BoardsTable";
import { About } from "./components/About";

const { appId } = atlasConfig;

export default function ProvidedApp() {
  return (
    <AppProvider appId={appId}>
      <App />
    </AppProvider>
  );
}

function App() {
  const { currentUser, logOut } = useApp();
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards);
  const activeBoard = boards.find((board) => board.isActive);
  if (!activeBoard && boards.length > 0)
    dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));

  return (
    <div>
      {currentUser ? (
        <>
          <Routes>
            <Route path="/" element={<BoardsLayout />} />
            {/* <Route path="/board/:id" element={<PublicBoardsLayout />} /> */}
            <Route path="/account" element={<AccountSettingsPage />} />
            <Route path="/reports" element={<BoardsTable />} />
            <Route path="/releasenotes" element={<ReleaseNotes />} />
            <Route path="/about" element={<About />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/board/:id" element={<PublicBoardsLayout />} />
            <Route path="/confirmEmail" element={<ConfirmEmail />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/about" element={<About />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/releasenotes" element={<ReleaseNotes />} />
            <Route path="*" element={<WelcomePage />} />
          </Routes>
        </>
      )}
    </div>
  );
}
