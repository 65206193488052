import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ElipsisMenu from "../components/ElipsisMenu";
import elipsis from "../assets/icon-vertical-ellipsis.svg";
import boardsSlice from "../redux/boardsSlice";
import Subtask from "../components/Subtask";
import AddEditTaskModal from "./AddEditTaskModal";
import DeleteModal from "./DeleteModal";
import { AppProvider, useApp } from "../RealmApp";
import PublicSubtask from "../components/PublicSubtask";
import toast, { Toaster } from "react-hot-toast";
import crossIcon from "../assets/icon-cross.svg";

function TaskModal({ taskIndex, colIndex, setIsTaskModalOpen }) {
  const { currentUser, logOut } = useApp();
  const dispatch = useDispatch();
  const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const isCurrentUserBoardOwner =
    currentUser && board ? board.owner_id === currentUser.id : false;
  const columns = board.columns;
  const col = columns.find((col, i) => i === colIndex);
  const task = col.tasks.find((task, i) => i === taskIndex);
  const subtasks = task.subtasks;

  let completed = 0;
  subtasks.forEach((subtask) => {
    if (subtask.isCompleted) {
      completed++;
    }
  });

  const [status, setStatus] = useState(task.status);
  const [newColIndex, setNewColIndex] = useState(columns.indexOf(col));
  const onChange = (e) => {
    setStatus(e.target.value);
    setNewColIndex(e.target.selectedIndex);
  };

  const onClose = (e) => {
    if (e.target !== e.currentTarget) {
      return;
    }
    dispatch(
      boardsSlice.actions.setTaskStatus({
        taskIndex,
        colIndex,
        newColIndex,
        status,
      })
    );
  };

  const onSaveClose = async (e) => {
    //save boards to the cloud and dispatch event and close
    const updateResult = await currentUser.functions.UpdateBoard({
      ...board,
      newColumns: board.columns,
    });
    if (updateResult) {
      dispatch(
        boardsSlice.actions.setTaskStatus({
          taskIndex,
          colIndex,
          newColIndex,
          status,
        })
      );
      setIsTaskModalOpen(false);
    } else {
      toast("Couldn't save any changes. You may not have permissions.");
      setIsTaskModalOpen(false);
      // window.location.reload();
    }
  };

  const onNoSaveClose = async (e) => {
    setIsTaskModalOpen(false);
  };

  const onDeleteBtnClick = (e) => {
    if (e.target.textContent === "Delete") {
      if (
        board.updateTaskStatusOnlyEmails.includes(
          currentUser.customData.userEmail
        ) ||
        board.readAccessEmails.includes(currentUser.customData.userEmail)
      ) {
        toast("You do not have permissions to perform this action");
      } else {
        dispatch(boardsSlice.actions.deleteTask({ taskIndex, colIndex }));
        setIsTaskModalOpen(false);
        setIsDeleteModalOpen(false);
      }
    } else {
      setIsDeleteModalOpen(false);
    }
  };

  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);

  const setOpenEditModal = () => {
    setIsAddTaskModalOpen(true);
    setIsElipsisMenuOpen(false);
  };

  const setOpenDeleteModal = () => {
    setIsElipsisMenuOpen(false);
    setIsDeleteModalOpen(true);
  };

  return (
    <div
      onClick={onClose}
      className=" fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown "
    >
      {/* MODAL SECTION */}
      <Toaster />
      <div className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  my-auto  bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl">
        <div className=" relative flex   justify-between w-full items-center">
          <h1 className=" text-lg">{task.title}</h1>

          {isCurrentUserBoardOwner ? (
            <>
              <img
                onClick={() => {
                  setIsElipsisMenuOpen((prevState) => !prevState);
                }}
                src={elipsis}
                alt="elipsis"
                className=" cursor-pointer h-6 pl-4"
              />
              {isElipsisMenuOpen && (
                <ElipsisMenu
                  setOpenEditModal={setOpenEditModal}
                  setOpenDeleteModal={setOpenDeleteModal}
                  type="Task"
                />
              )}
            </>
          ) : (
            <>
              <img
                src={crossIcon}
                alt=" close "
                className=" cursor-pointer "
                onClick={onNoSaveClose}
              />
            </>
          )}
        </div>
        <p className=" text-gray-500 font-[600] tracking-wide text-xs pt-2">
          {task.releasedate
            ? new Date(task.releasedate).toLocaleString()
            : task.releasedate}
        </p>
        <p className=" text-gray-500 font-[600] tracking-wide text-xs pt-2">
          {task.description}
        </p>
        <br />

        {!isNaN(completed / subtasks.length) && (
          <>
            <div className="w-full h-2 bg-gray-200 rounded overflow-hidden">
              <div
                className="h-full bg-green-500"
                style={{ width: `${(completed / subtasks.length) * 100}%` }}
              ></div>
            </div>

            <p className="text-sm">
              {isNaN(completed / subtasks.length)
                ? "N/A"
                : `${((completed / subtasks.length) * 100).toFixed(2)}%`}{" "}
              Completed
            </p>
          </>
        )}
        <p className=" pt-6 text-gray-500 tracking-widest text-sm">
          Subtasks ({completed} of {subtasks.length})
        </p>

        {/* subtasks section */}

        <div className=" mt-3 space-y-2">
          {subtasks.map((subtask, index) => {
            return currentUser ? (
              <Subtask
                index={index}
                taskIndex={taskIndex}
                colIndex={colIndex}
                key={index}
              />
            ) : (
              <PublicSubtask
                index={index}
                taskIndex={taskIndex}
                colIndex={colIndex}
                key={index}
              />
            );
          })}
        </div>

        {/* Current Status Section */}

        <div className="mt-8 flex flex-col space-y-3">
          {currentUser && (
            <>
              <label className="  text-sm dark:text-white text-gray-500">
                Current Status
              </label>
              <select
                className=" select-status flex-grow px-4 py-2 rounded-md text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#635fc7] outline-none"
                value={status}
                onChange={onChange}
              >
                {columns.map((col, index) => (
                  <option className="status-options" key={index}>
                    {col.name}
                  </option>
                ))}
              </select>
              <button
                onClick={onSaveClose}
                className="bg-[#635fc7] hover:bg-green-600 items-center text-white py-2 rounded-full"
              >
                Save
              </button>
            </>
          )}
          <button
            onClick={onNoSaveClose}
            className="bg-red-500 items-center text-white py-2 rounded-full"
          >
            Cancel
          </button>
        </div>
      </div>
      {isDeleteModalOpen && (
        <DeleteModal
          onDeleteBtnClick={onDeleteBtnClick}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          type="task"
          title={task.title}
        />
      )}

      {isAddTaskModalOpen && (
        <AddEditTaskModal
          setIsAddTaskModalOpen={setIsAddTaskModalOpen}
          setIsTaskModalOpen={setIsTaskModalOpen}
          type="edit"
          taskIndex={taskIndex}
          prevColIndex={colIndex}
        />
      )}
    </div>
  );
}

export default TaskModal;
