import React, { useState, useEffect } from "react";
import atlasConfig from "../atlasConfig.json";
import logo from "../assets/logo-mobile.svg";
import confirmlogo from "../assets/icon-confirm.png";
import { Button, Input } from "@material-tailwind/react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { AppProvider, useApp } from "../RealmApp";
import * as Realm from "realm-web";
import toast, { Toaster } from "react-hot-toast";
import { App } from "realm-web";
const { baseUrl, appId } = atlasConfig;

export function ConfirmEmail() {
  const [confirmed, setConfirmed] = useState(false);
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const { currentUser, logOut } = useApp();

  const app = new App({ id: appId });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const tokenId = queryParams.get("tokenId");
    setToken(token);
    setTokenId(tokenId);
  }, []);
  return (
    <div className=" overflow-hidden  overflow-x-scroll container mx-auto m-40">
      <>
        <Toaster />

        <center>
          {confirmed ? (
            <>
              <h1>Congratulations!</h1>
              <br />
              <h2>Your email has been confirmed.</h2>
              <h2>You can now login to the application.</h2>
              <img src={confirmlogo} className="w-35 h-35" />
              <Link to="/" className="">
                <Button
                  size="md"
                  className="w-60 h-10 mt-10 bg-green-500"
                  ripple={true}
                >
                  Login{" "}
                </Button>
              </Link>
            </>
          ) : (
            <>
              <img src={logo} />
              <h1>Thank you for registering for Copeo Boards.</h1>
              <h2>
                You must confirm your email before you start using the
                application.
              </h2>
              <Button
                size="lg"
                className="w-80 h-20 mt-5  bg-[#635fc7] "
                ripple={true}
                onClick={async () => {
                  if (token && tokenId) {
                    try {
                      await app.emailPasswordAuth.confirmUser({
                        token,
                        tokenId,
                      });

                      setConfirmed(true);
                    } catch (error) {
                      console.log(error);
                      toast(
                        "Couldn't confirm your email. The request might have been expired."
                      );
                    }
                  } else {
                    toast("Invalid Page");
                  }
                }}
              >
                Confirm Now{" "}
              </Button>
            </>
          )}
        </center>
      </>
    </div>
  );
}
