import React, { useState, useEffect } from "react";
import atlasConfig from "../atlasConfig.json";
import { Button } from "@material-tailwind/react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { AppProvider, useApp } from "../RealmApp";
import * as Realm from "realm-web";
import { App } from "realm-web";
import toast, { Toaster } from "react-hot-toast";
const { baseUrl, appId } = atlasConfig;

export function ResetPassword() {
  const { currentUser, logOut } = useApp();
  const [confirmed, setConfirmed] = useState(false);
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const app = new App({ id: appId });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const tokenId = queryParams.get("tokenId");
    setToken(token);
    setTokenId(tokenId);
  }, []);
  return (
    <>
      <section class="bg-white dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
          <Toaster />

          <a
            href="#/releasenotes"
            class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-blue-700 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-800"
          >
            <span class="text-xs bg-[#635fc7] rounded-full text-white px-4 py-1.5 mr-3">
              New
            </span>{" "}
            <span class="text-sm font-medium">
              See what's latest in the release notes
            </span>
            <svg
              aria-hidden="true"
              class="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            Reset Password{" "}
          </h1>
          <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
            Please enter your new password and confirm it by entering it again
            in the confirm password input below.
          </p>
          <form>
            <div class="mb-6">
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="•••••••••"
                required
              />
            </div>
            <div class="mb-6">
              <label
                for="confirm_password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Confirm password
              </label>
              <input
                type="password"
                value={confirmPassword}
                id="confirm_password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="•••••••••"
                required
              />
            </div>

            <button
              onClick={async () => {
                console.log("te");
                if (token == "" || tokenId == "") {
                  toast(
                    "Required parameters are missing. Cannot  proceed further."
                  );
                } else {
                  if (
                    password === confirmPassword &&
                    password !== "" &&
                    password.length > 8
                  ) {
                    try {
                      await app.emailPasswordAuth.resetPassword({
                        token,
                        tokenId,
                        password,
                      });
                      toast(
                        "Password Changed/Reset successfully. You may use the new password to login now."
                      );
                    } catch (error) {
                      toast(
                        "Could not reset your password. Please try again later."
                      );
                    }
                  } else {
                    toast(
                      "Please make sure the passwords match and greater than 8 characters in length."
                    );
                  }
                }
              }}
              class="text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Confirm Change Password
            </button>
          </form>
          <Link to="/">
            <Button
              size="md"
              className="w-60 h-10 mt-5 bg-[#635fc7] "
              ripple={true}
            >
              Back to Home{" "}
            </Button>
          </Link>
        </div>
        <div class="bg-gradient-to-b from-blue-50 to-transparent dark:from-blue-900 w-full h-full absolute top-0 left-0 z-0"></div>
      </section>
    </>
  );
}
