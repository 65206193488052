import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import crossIcon from "../assets/icon-cross.svg";
import boardsSlice from "../redux/boardsSlice";
import { AppProvider, useApp } from "../RealmApp";
import toast, { Toaster } from "react-hot-toast";

function AddEditTaskModal({
  type,
  device,
  setIsTaskModalOpen,
  setIsAddTaskModalOpen,
  taskIndex,
  prevColIndex = 0,
}) {
  const { currentUser, logOut } = useApp();
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [title, setTitle] = useState("");
  const [releasedate, setReleaseDate] = useState("");
  const [description, setDescription] = useState("");
  const [board, setBoard] = useState(
    useSelector((state) => state.boards).find((board) => board.isActive)
  );
  // const [board, setBoard] = useState(
  //   useSelector((state) => state.boards).find((board) => board.isActive)
  // );

  const columns = board.columns;
  const col = columns.find((col, index) => index === prevColIndex);
  const task = col ? col.tasks.find((task, index) => index === taskIndex) : [];
  const [status, setStatus] = useState(columns[prevColIndex].name);
  const [newColIndex, setNewColIndex] = useState(prevColIndex);

  const [subtasks, setSubtasks] = useState([
    // { title: "", isCompleted: false, id: uuidv4(), comments: "" },
    // { title: "", isCompleted: false, id: uuidv4(), comments: "" },
  ]);

  const onChangeSubtasks = (id, newValue) => {
    setSubtasks((prevState) => {
      const newState = [...prevState];
      const subtask = newState.find((subtask) => subtask.id === id);
      subtask.title = newValue;
      return newState;
    });
  };

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
    setNewColIndex(e.target.selectedIndex);
  };

  const hasPermissions = () => {
    if (
      board.updateTaskStatusOnlyEmails.includes(
        currentUser.customData.userEmail
      ) ||
      board.readAccessEmails.includes(currentUser.customData.userEmail)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validate = () => {
    setIsValid(false);
    if (!title.trim()) {
      toast("Please fill the Task Name");
      return false;
    }
    // if (!releasedate.trim()) {
    //   toast("Please fill the Target Date");
    //   return false;
    // }
    for (let i = 0; i < subtasks.length; i++) {
      if (!subtasks[i].title.trim()) {
        toast("Please fill all the Subtasks");
        return false;
      }
    }
    setIsValid(true);
    return true;
  };

  if (type === "edit" && isFirstLoad) {
    setSubtasks(
      task.subtasks.map((subtask) => {
        return { ...subtask, id: uuidv4() };
      })
    );
    setTitle(task.title);
    setReleaseDate(task.releasedate);
    setDescription(task.description);
    setIsFirstLoad(false);
  }

  const onDelete = (id) => {
    setSubtasks((prevState) => prevState.filter((el) => el.id !== id));
  };

  const onSubmit = async (type) => {
    if (type === "add") {
      dispatch(
        boardsSlice.actions.addTask({
          title,
          description,
          releasedate,
          subtasks,
          status,
          newColIndex,
        })
      );
    } else {
      dispatch(
        boardsSlice.actions.editTask({
          title,
          description,
          releasedate,
          subtasks,
          status,
          taskIndex,
          prevColIndex,
          newColIndex,
        })
      );
    }
    toast("Make sure you save your changes using the save button.");
  };

  return (
    <div
      className={
        device === "mobile"
          ? "  py-6 px-6 pb-40  absolute overflow-y-scroll  left-0 flex  right-0 bottom-[-100vh] top-0 dropdown "
          : "  py-6 px-6 pb-40  absolute overflow-y-scroll  left-0 flex  right-0 bottom-0 top-0 dropdown "
      }
      onClick={(e) => {
        //disabled the close when clicked outside the modal
        // if (e.target !== e.currentTarget) {
        //   return;
        // }
        // setIsAddTaskModalOpen(false);
      }}
    >
      <Toaster />

      {/* Modal Section */}
      <div
        className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  my-auto  bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold
       shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl"
      >
        <div class="relative  ...">
          <div class="absolute top-0 right-0 ">
            <img
              src={crossIcon}
              alt=" close "
              className=" cursor-pointer "
              onClick={() => {
                setIsAddTaskModalOpen(false);
              }}
            />
          </div>
        </div>
        <h3 className=" text-lg ">
          {type === "edit" ? "Edit" : "Add New"} Task
        </h3>

        {/* Task Name */}
        <div className="mt-8 flex flex-col space-y-1">
          <label className="  text-sm dark:text-white text-gray-500">
            Task Name
          </label>
          <input
            value={title}
            disabled={!hasPermissions()}
            onChange={(e) => setTitle(e.target.value)}
            id="task-name-input"
            type="text"
            className=" bg-transparent  px-4 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-1  ring-0  "
            placeholder=" e.g Take coffee break"
          />
        </div>
        {/* Task Date */}
        <div className="mt-4 flex flex-col space-y-1">
          <label className="  text-sm dark:text-white text-gray-500">
            Target Date
          </label>
          <input
            value={releasedate}
            disabled={!hasPermissions()}
            onChange={(e) => setReleaseDate(e.target.value)}
            id="task-date-input"
            type="datetime-local"
            className=" bg-transparent  px-4 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-1  ring-0  "
            placeholder="Ex: Take coffee break"
          />
        </div>

        {/* Description */}
        <div className="mt-4 flex flex-col space-y-1">
          <label className="  text-sm dark:text-white text-gray-500">
            Description
          </label>
          <textarea
            value={description}
            disabled={!hasPermissions()}
            onChange={(e) => setDescription(e.target.value)}
            id="task-description-input"
            className=" bg-transparent outline-none min-h-[100px] focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-[1px] "
            placeholder="Ex: Sleek horizontal aluminum screen fence."
          />
        </div>

        {/* Subtasks */}
        <div className="mt-8 flex flex-col space-y-3">
          <label className="  text-sm dark:text-white text-gray-500">
            Subtasks
          </label>

          {subtasks.map((subtask, index) => (
            <div key={index} className=" flex items-center w-full ">
              <input
                onChange={(e) => {
                  onChangeSubtasks(subtask.id, e.target.value);
                }}
                type="text"
                disabled={!hasPermissions()}
                value={subtask.title}
                className=" bg-transparent outline-none focus:border-0 flex-grow px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-[1px]  "
                placeholder="Subtask"
              />
              <img
                src={crossIcon}
                alt=" delete "
                onClick={() => {
                  if (!hasPermissions()) {
                    toast("You do not have permissions to delete sub tasks");
                  } else {
                    onDelete(subtask.id);
                  }
                }}
                className=" m-4 cursor-pointer "
              />{" "}
            </div>
          ))}

          <button
            className=" w-full items-center dark:text-[#635fc7] dark:bg-white  text-white bg-[#635fc7] py-2 rounded-full "
            onClick={() => {
              if (!hasPermissions()) {
                toast("You do not have permissions to add new tasks.");
              } else {
                setSubtasks((state) => [
                  ...state,
                  { title: "", isCompleted: false, id: uuidv4() },
                ]);
              }
            }}
          >
            + Add New Subtask
          </button>
        </div>

        {/* current Status  */}
        <div className="mt-8 flex flex-col space-y-3">
          <label className="  text-sm dark:text-white text-gray-500">
            Current Status or Column Group
          </label>
          <select
            value={status}
            onChange={onChangeStatus}
            className=" select-status flex-grow px-4 py-2 rounded-md text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#635fc7] outline-none"
          >
            {columns.map((column, index) => (
              <option key={index}>{column.name}</option>
            ))}
          </select>
          <button
            onClick={() => {
              const isValid = validate();
              if (isValid) {
                onSubmit(type);
                setIsAddTaskModalOpen(false);
                type === "edit" && setIsTaskModalOpen(false);
              }
            }}
            className=" w-full items-center text-white bg-[#635fc7] py-2 rounded-full hover:bg-green-600"
          >
            {type === "edit" ? " Save Edit" : "Create task"}
          </button>
          <button
            onClick={() => {
              setIsAddTaskModalOpen(false);
            }}
            className="bg-red-500 w-full items-center text-white py-2 rounded-full"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddEditTaskModal;
