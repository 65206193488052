import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import boardIcon from "../assets/icon-board.svg";
import sharedIcon from "../assets/icon-shared.png";
import ownerIcon from "../assets/icon-owner.png";
import useDarkMode from "../hooks/useDarkMode";
import darkIcon from "../assets/icon-dark-theme.svg";
import lightIcon from "../assets/icon-light-theme.svg";

import showSidebarIcon from "../assets/icon-show-sidebar.svg";
import hideSidebarIcon from "../assets/icon-hide-sidebar.svg";

import boardsSlice from "../redux/boardsSlice";
import AddEditBoardModal from "../modals/AddEditBoardModal";
// import { current } from "@reduxjs/toolkit";
import { useApp } from "../RealmApp";

function Sidebar({ isSideBarOpen, setIsSideBarOpen }) {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");

  const { currentUser } = useApp();
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [colorTheme, setTheme] = useDarkMode();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const boards = useSelector((state) => state.boards);

  const totalBoardFinishPercent = (board) => {
    var tableItems = [];
    var boardName = board.name;
    for (var vv = 0; vv < board.columns.length; vv++) {
      var columnName = board.columns[vv].name;
      var tasks = board.columns[vv].tasks;
      for (var vvv = 0; vvv < tasks.length; vvv++) {
        var taskTitle = tasks[vvv].title;
        var taskDescription = tasks[vvv].description;
        var releaseDate = tasks[vvv].releasedate;
        var subtasks = tasks[vvv].subtasks;
        for (var vvvv = 0; vvvv < subtasks.length; vvvv++) {
          var subTaskTitle = subtasks[vvvv].title;
          var subTaskComments = "";
          var subTaskIsCompleted = subtasks[vvvv].isCompleted;
          var subTaskId = subtasks[vvvv].id;
          var item = {
            boardName: boardName,
            groupName: columnName,
            taskTitle: taskTitle,
            taskDescription: taskDescription,
            releaseDate: releaseDate,
            subTaskTitle: subTaskTitle,
            subTaskComments: subTaskComments,
            subTaskId: subTaskId,
            subTaskIsCompleted: subTaskIsCompleted,
          };
          tableItems.push(item);
        }
      }
    }
    const finishedTasks = tableItems.filter((subtask) => {
      return subtask.subTaskIsCompleted === true;
    });

    if (isNaN((finishedTasks.length / tableItems.length) * 100)) {
      return "0.0%";
    } else {
      return (
        ((finishedTasks.length / tableItems.length) * 100).toFixed(1) + "%"
      );
    }
  };

  const filteredBoards = boards.filter((board) => {
    return board.name.toLowerCase().includes(searchInput.toLowerCase());
  });

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  const toggleSidebar = () => {
    setIsSideBarOpen((curr) => !curr);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  if (searchInput.length > 0) {
    console.log(searchInput);
    // boards.filter((boarditem) => {
    //   return boarditem.name.toLowerCase().includes(searchInput);
    // });
  }

  return (
    <div>
      <div
        className={
          isSideBarOpen
            ? `min-w-[261px] max-w-[18rem] bg-white dark:bg-[#2b2c37]  fixed top-[72px] h-screen  items-center left-0 z-20`
            : ` bg-[#635FC7] dark:bg-[#2b2c37] dark:hover:bg-[#635FC7] top-auto bottom-10 justify-center items-center hover:opacity-80 cursor-pointer  p-0 transition duration-300 transform fixed felx w-[56px] h-[48px] rounded-r-full  `
        }
      >
        <div>
          {/* reWrite modal  */}

          {isSideBarOpen && (
            <div className=" bg-white  dark:bg-[#2b2c37]    w-full   py-4 rounded-xl">
              <div className="mx-2 mb-2  p-4 relative space-x-2 bg-slate-100 dark:bg-[#20212c] flex justify-center items-center rounded-lg">
                <img src={lightIcon} alt="sun indicating light mode" />

                <Switch
                  checked={darkSide}
                  onChange={toggleDarkMode}
                  className={`${
                    darkSide ? "bg-[#635fc7]" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span
                    className={`${
                      darkSide ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>

                <img src={darkIcon} alt="moon indicating dark mode" />
              </div>
              <h3 className=" dark:text-gray-300 text-gray-600 font-semibold mx-4 mb-2 ">
                ALL BOARDS ({boards?.length})
              </h3>
              <input
                type="text"
                className=" bg-transparent  px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-600 focus:outline-[#635fc7] outline-1  ring-0 ml-3 dark:text-white"
                placeholder="Search boards here"
                onChange={handleChange}
                value={searchInput}
              />

              <div className="  dropdown-borad flex flex-col h-[60vh]  justify-between overflow-auto">
                {currentUser && (
                  <>
                    <div
                      className=" flex  items-baseline space-x-2  mr-8 rounded-r-full duration-500 ease-in-out cursor-pointer text-[#635fc7] px-5 py-4 hover:bg-[#635fc71a] hover:text-[#635fc7] dark:hover:bg-white  "
                      onClick={() => {
                        setIsBoardModalOpen(true);
                      }}
                    >
                      <img
                        src={boardIcon}
                        className="   filter-white  h-4 "
                        alt=""
                      />
                      <p className=" text-lg font-bold  ">Create New Board </p>
                    </div>
                  </>
                )}
                <div className={currentUser ? "" : "mt-2 "}>
                  {filteredBoards.map((board, index) => (
                    <div
                      className={` flex items-baseline space-x-2 px-5 mr-8 rounded-r-2xl duration-500 ease-in-out py-4 cursor-pointer hover:bg-[#635fc71a] hover:text-[#635fc7] dark:hover:bg-white dark:hover:text-[#635fc7] dark:text-white  ${
                        board.isActive &&
                        " bg-[#635fc7] rounded-r-2xl text-white mr-8 "
                      } `}
                      key={index}
                      onClick={() => {
                        // dispatch(boardsSlice.actions.setBoardActive({ index }));
                        dispatch(
                          boardsSlice.actions.setBoardActiveByBoardId({ board })
                        );
                        setSearchInput("");
                      }}
                    >
                      {/* <img src={boardIcon} className="  filter-white  h-4 " />{" "} */}
                      {/* Show shared logo when the board is a shared one */}
                      {currentUser && (
                        <>
                          {board.owner_id === currentUser.id ? (
                            <>
                              <img
                                src={ownerIcon}
                                className="  filter-white  h-4 "
                                alt=""
                              />{" "}
                            </>
                          ) : (
                            <>
                              <img
                                src={sharedIcon}
                                className="  filter-white  h-4 "
                                alt=""
                              />{" "}
                            </>
                          )}
                        </>
                      )}
                      <p className=" text-sm font-bold break-words	">
                        {board.name}
                      </p>
                      <p className=" text-xs font-light break-words">
                        {totalBoardFinishPercent(board)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Sidebar hide/show toggle */}
          {isSideBarOpen ? (
            <div
              onClick={() => toggleSidebar()}
              className=" flex  items-center mt-2  absolute bottom-16  text-lg font-bold  rounded-r-2xl hover:text-[#635FC7] cursor-pointer mr-6 mb-8 px-8 py-4 hover:bg-[#635fc71a] dark:hover:bg-white  space-x-2 justify-center  my-4 text-gray-500 "
            >
              <img
                className=" min-w-[20px]"
                src={hideSidebarIcon}
                alt=" side bar show/hide"
              />
              {isSideBarOpen && <p> Hide Sidebar </p>}
            </div>
          ) : (
            <div className=" absolute p-5  " onClick={() => toggleSidebar()}>
              <img src={showSidebarIcon} alt="showSidebarIcon" />
            </div>
          )}
        </div>
      </div>

      {isBoardModalOpen && (
        <AddEditBoardModal
          type="add"
          setIsBoardModalOpen={setIsBoardModalOpen}
        />
      )}
    </div>
  );
}

export default Sidebar;
