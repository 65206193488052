import React from "react";
import img404 from "../assets/notfound.jpg";
import Logo from "../assets/logo-mobile.svg";

import { AppProvider, useApp } from "../RealmApp";

const NotFound = () => {
  const { currentUser, logOut } = useApp();
  return (
    <center>
      <div className=" p-4 fixed left-0 bg-white dark:bg-[#2b2c37] z-50 right-0 ">
        <header className=" flex justify-between dark:text-white items-center  ">
          {/* Left Side  */}
          <div className=" flex items-center space-x-2  md:space-x-4">
            <a href="/">
              {" "}
              <img src={Logo} alt=" Logo " className=" h-6 w-6" />
            </a>

            <h3 className=" md:text-2xl  hidden md:inline-block font-bold  font-sans">
              <a href="/">Boards</a>
              <div className=" md:text-xs   md:inline-block font-extralight  font-sans">
                by Copeo
              </div>
            </h3>
          </div>

          {/* Right Side */}
          {currentUser && (
            <>
              <div className=" flex space-x-4 items-center md:space-x-2 ">
                <a href="/">
                  <button
                    className="button bg-[#635fc7] md:block hover:bg-green-600 text-sm m-1"
                    onClick={logOut}
                  >
                    Logout
                  </button>
                </a>
              </div>
            </>
          )}
        </header>
      </div>
      <img src={img404} width="600" height="500" />
      <h1>Sorry, page not found.</h1>

      <h2>
        If you're trying to access a public board, you must be logged out or
        proceed to use an incognito window.
      </h2>
      <br />
      <h4>
        <b>
          <a href="/">
            <button className="rounded-full bg-[#635fc7] h-10 w-40 text-white">
              Go Back Home
            </button>
          </a>
        </b>
      </h4>
      <br />
      <br />

      <br />
      <h6>
        <a href="https://www.freepik.com/free-vector/page-found-concept-illustration_7887410.htm#query=404&position=3&from_view=keyword&track=sph">
          Image by storyset
        </a>{" "}
        on Freepik
      </h6>
    </center>
  );
};

export default NotFound;
