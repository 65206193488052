import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
  Card,
  List,
  ListItem,
} from "@material-tailwind/react";
import {
  HomeIcon,
  BellIcon,
  UserIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid";
import { Button, Input } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import developer from "../assets/devprofilepic.jpeg";

export function About() {
  return (
    <div className="overflow-hidden  overflow-x-scroll  mx-auto dark:bg-[#2b2c37] ">
      <center>
        <Link to="/" className="">
          <Button
            size="md"
            className="w-40 h-10 mt-10  bg-[#635fc7] "
            ripple={true}
          >
            Go Back{" "}
          </Button>
        </Link>
        <div className="w-[32rem]">
          <br />
          <Timeline className="mt-5 ml-3">
            <TimelineItem className="mb-12">
              <TimelineHeader>
                <TimelineIcon className="p-2">
                  <UserIcon className="h-4 w-4" />
                </TimelineIcon>
                <Typography
                  variant="h5"
                  className="dark:text-white text-[#635fc7]"
                >
                  About developer:
                </Typography>
              </TimelineHeader>
              <TimelineBody>
                <Typography
                  color="gary"
                  className="font-normal text-gray-600 dark:text-gray-400"
                >
                  <begin>
                    <img
                      className="h-48 w-48 mt-2 rounded-full"
                      src={developer}
                      alt="profile image"
                    />
                    <ul>
                      <li>
                        <begin>
                          <br />
                          Hello! I'm Medha. I'm currently a Sr.Product Manager -
                          Technical at AWS. I built this full-stack, cloud
                          application as a hobby project 1/ to help myself keep
                          track of the tasks that I work on and, 2/ to not loose
                          my grip on coding, and keep honing my skills. Though
                          it started as a hobby project, I realized how helpful
                          & handy this has become to me and for some of my
                          friends. So, I decided to make this public along with
                          the "Gen AI" capabilities to help PMs, SDEs and in
                          general anyone for their task management scenarios.
                          Though fully implemented from scratch, the look and
                          feel (DESIGN) of the project has been inspired from a{" "}
                          <a
                            className="text-blue-500"
                            href="https://www.frontendmentor.io/challenges/kanban-task-management-web-app-wgQLt-HlbB"
                            target="_blank"
                          >
                            FrontEnd Mentor Coding Challenge.{" "}
                          </a>{" "}
                          FYI, I've taken necessary permissions from the team to
                          use the DESIGN for my project. Some of the icons used
                          in this project are taken from the freemium websites
                          like{" "}
                          <a
                            href="https://icons8.com/"
                            target="_blank"
                            className="text-blue-500"
                          >
                            icons8.com
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://lottiefiles.com/search?q=ai&category=animations"
                            target="_blank"
                            className="text-blue-500"
                          >
                            lottiefiles.com
                          </a>
                        </begin>
                      </li>
                      <li>
                        <br />
                      </li>
                      <li>
                        {" "}
                        This project is built on ReactJS, uses Azure App Service
                        to host the frontend, MongoDB on an AWS VM for the
                        database and NodeJS backend APIs, Realm for User
                        Authentication, and OpenAI & ChatGPT APIs and costs
                        money each month.
                        <p className=" cursor-pointer dark:text-blue-500 text-blue-500">
                          <Link
                            to="https://www.paypal.com/paypalme/medhakarri"
                            target="_blank"
                          >
                            Please Support Further Development
                          </Link>
                        </p>
                        <br />
                        <br />I strongly believe in the notion that "my projects
                        reflect the caliber of my work." I'm open for any
                        feedback and improvement. You can email me at{" "}
                        <u>
                          <a
                            href="mailto:medhachaitanya@outlook.com"
                            target="_blank"
                          >
                            medhachaitanya@outlook.com
                          </a>
                        </u>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <b className="text-[#635fc7] dark:text-white">
                      {" "}
                      If you own a Windows 10/11 laptop,
                    </b>{" "}
                    you should not miss this battery app which helps protect
                    your laptop battery. "Pure Battery Analytics" is currently
                    the best rated and top battery app on the Microsoft Store.
                    You can get it from the Microsoft Store
                    <a
                      href="https://apps.microsoft.com/store/detail/pure-battery-analytics/9NBLGGH4X4K3"
                      target="_blank"
                    >
                      {" "}
                      here.
                    </a>
                    <br />
                    <br />
                    <a
                      href="https://apps.microsoft.com/store/detail/9NBLGGH4X4K3?launch=true&mode=mini"
                      target="_blank"
                    >
                      <img src="https://get.microsoft.com/images/en-US%20dark.svg" />
                    </a>
                    <br />
                    <a href="https://medhachaitanya.github.io/" target="_blank">
                      Connect with me on LinkedIn and other websites @{" "}
                      <u>medhakarri.info</u>
                    </a>
                  </begin>
                </Typography>
              </TimelineBody>
            </TimelineItem>
          </Timeline>
        </div>
      </center>
    </div>
  );
}
