export const AddNewBoardTitle = "Board";
export const BoardNameTitle = "Board Name";
export const BoardColumnsTitle = "Board Columns";
export const UpdateSubTaskStatusUserEmailsTitle =
  "Users below can update sub-task status";
export const AddSubTaskStatusUserEmailTitle = "+ Add New User";
export const ReadOnlyBoardUserEmailsTitle =
  "Users below can see (read-only) board contents";
export const AddReadOnlyUserEmailTitle = "+ Add New User";
export const BoardPublicTitle = "Make this board public ?";
