import React, { useState, useEffect } from "react";
import atlasConfig from "../atlasConfig.json";
import { Button } from "@material-tailwind/react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { AppProvider, useApp } from "../RealmApp";
import * as Realm from "realm-web";
import toast, { Toaster } from "react-hot-toast";
import { App } from "realm-web";
const { baseUrl, appId } = atlasConfig;

export function ForgotPassword() {
  const { currentUser, logOut } = useApp();
  const app = new App({ id: appId });
  const [email, setEmail] = useState(
    currentUser ? currentUser.profile.email : ""
  );

  // useEffect(() => {

  // }, []);
  return (
    <>
      <Toaster />

      <section class="bg-white dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
          <a
            href="#/releasenotes"
            class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-blue-700 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-800"
          >
            <span class="text-xs bg-[#635fc7] rounded-full text-white px-4 py-1.5 mr-3">
              New
            </span>{" "}
            <span class="text-sm font-medium">
              See what's latest in the release notes
            </span>
            <svg
              aria-hidden="true"
              class="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            Change/Reset Password
          </h1>
          <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
            Please enter your e-mail below to receive a password reset email.
          </p>
          <form class="w-full max-w-md mx-auto">
            <label
              for="default-email"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Email sign-up
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
              </div>
              <input
                type="email"
                id="default-email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter your email here..."
                required
              />
              <button
                type="button"
                onClick={async () => {
                  try {
                    await app.emailPasswordAuth.sendResetPasswordEmail({
                      email: email,
                    });
                    toast("Please check your inbox");
                  } catch (error) {
                    console.log(error);
                    toast("Couldn't send a password reset email");
                  }
                }}
                class="text-white absolute right-2.5 bottom-2.5 bg-[#635fc7] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Send
              </button>
            </div>
          </form>
          <Link to="/">
            <Button
              size="md"
              className="w-60 h-10 mt-5 bg-[#635fc7] "
              ripple={true}
            >
              Back to Home{" "}
            </Button>
          </Link>
        </div>
        <div class="bg-gradient-to-b from-blue-50 to-transparent dark:from-blue-900 w-full h-full absolute top-0 left-0 z-0"></div>
      </section>
    </>
  );
}
